<section
  class="page-faqs"
  itemscope
  itemtype="https://schema.org/FAQPage"
  [ngClass]="{ no_border_radius: noBorderRadius }"
>
  <h2 class="title">{{ 'PAGES.LANDING.FAQ.FAQ_HEADER' | translate }}</h2>
  <ul class="faq-list">
    <li
      class="faq-list-item"
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
    >
      <input class="faq-list-item__toggle" type="checkbox" id="faq[1]" />
      <h3 for="faq[1]" class="faq-list-item__title" itemprop="name">
        {{ 'PAGES.LANDING.FAQ.FAQ_1_QUESTION' | translate }}
      </h3>
      <div
        class="faq-list-item__answer"
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
      >
        <span itemprop="text">
          <p>{{ 'PAGES.LANDING.FAQ.FAQ_1_ANSWER' | translate }}</p>
        </span>
      </div>
    </li>

    <li
      class="faq-list-item"
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
    >
      <input class="faq-list-item__toggle" type="checkbox" id="faq[2]" />
      <h3 for="faq[2]" class="faq-list-item__title" itemprop="name">
        {{ 'PAGES.LANDING.FAQ.FAQ_2_QUESTION' | translate }}
      </h3>
      <div
        class="faq-list-item__answer"
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
      >
        <span itemprop="text">
          <p>{{ 'PAGES.LANDING.FAQ.FAQ_2_ANSWER' | translate }}</p>
        </span>
      </div>
    </li>

    <li
      class="faq-list-item"
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
    >
      <input class="faq-list-item__toggle" type="checkbox" id="faq[3]" />
      <h3 for="faq[3]" class="faq-list-item__title" itemprop="name">
        {{ 'PAGES.LANDING.FAQ.FAQ_3_QUESTION' | translate }}
      </h3>
      <div
        class="faq-list-item__answer"
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
      >
        <span itemprop="text">
          <p>{{ 'PAGES.LANDING.FAQ.FAQ_3_ANSWER' | translate }}</p>
        </span>
      </div>
    </li>

    <li
      class="faq-list-item"
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
    >
      <input class="faq-list-item__toggle" type="checkbox" id="faq[4]" />
      <h3 for="faq[4]" class="faq-list-item__title" itemprop="name">
        {{ 'PAGES.LANDING.FAQ.FAQ_4_QUESTION' | translate }}
      </h3>
      <div
        class="faq-list-item__answer"
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
      >
        <span itemprop="text">
          <p>{{ 'PAGES.LANDING.FAQ.FAQ_4_ANSWER' | translate }}</p>
        </span>
      </div>
    </li>

    <li
      class="faq-list-item"
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
    >
      <input class="faq-list-item__toggle" type="checkbox" id="faq[5]" />
      <h3 for="faq[5]" class="faq-list-item__title" itemprop="name">
        {{ 'PAGES.LANDING.FAQ.FAQ_5_QUESTION' | translate }}
      </h3>
      <div
        class="faq-list-item__answer"
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
      >
        <span itemprop="text">
          <p>{{ 'PAGES.LANDING.FAQ.FAQ_5_ANSWER' | translate }}</p>
        </span>
      </div>
    </li>

    <li
      class="faq-list-item"
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
    >
      <input class="faq-list-item__toggle" type="checkbox" id="faq[6]" />
      <h3 for="faq[6]" class="faq-list-item__title" itemprop="name">
        {{ 'PAGES.LANDING.FAQ.FAQ_6_QUESTION' | translate }}
      </h3>
      <div
        class="faq-list-item__answer"
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
      >
        <span itemprop="text">
          <p>{{ 'PAGES.LANDING.FAQ.FAQ_6_ANSWER' | translate }}</p>
        </span>
      </div>
    </li>

    <li
      class="faq-list-item"
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
    >
      <input class="faq-list-item__toggle" type="checkbox" id="faq[7]" />
      <h3 for="faq[7]" class="faq-list-item__title" itemprop="name">
        {{ 'PAGES.LANDING.FAQ.FAQ_7_QUESTION' | translate }}
      </h3>
      <div
        class="faq-list-item__answer"
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
      >
        <span itemprop="text">
          <p>{{ 'PAGES.LANDING.FAQ.FAQ_7_ANSWER' | translate }}</p>
        </span>
      </div>
    </li>

    <li
      class="faq-list-item"
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
    >
      <input class="faq-list-item__toggle" type="checkbox" id="faq[8]" />
      <h3 for="faq[8]" class="faq-list-item__title" itemprop="name">
        {{ 'PAGES.LANDING.FAQ.FAQ_8_QUESTION' | translate }}
      </h3>
      <div
        class="faq-list-item__answer"
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
      >
        <span itemprop="text">
          <p>{{ 'PAGES.LANDING.FAQ.FAQ_8_ANSWER' | translate }}</p>
        </span>
      </div>
    </li>
  </ul>
  <a [href]="externalService.createTaskUrl">
    <button class="handyhand_button blue_button ctaBtn">
      {{ 'NAVIGATION.BTN.CREATE_TASK' | translate }}
    </button>
  </a>
</section>
