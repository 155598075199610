import { Component, Input } from '@angular/core';
import { TranslatePipe } from '@app/pipes/translate.pipe';
import { ExternalService } from '@app/services/http/external.service';
import {NgClass} from "@angular/common";

@Component({
    imports: [TranslatePipe, NgClass],
    providers: [ExternalService],
    selector: 'app-landing-page-faq',
    templateUrl: './landingFaq.Component.html',
    styleUrls: ['./landingFaq.Component.scss']
})
export class LandingFaqComponent {
  @Input() isPriorWhite?: boolean;
  @Input() noBorderRadius?: boolean;
  constructor(public externalService: ExternalService) {}
}
